<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('CustomerReceivable',2)"
        :items="outgoingInvoices2"
        :items-context="outgoingInvoicesContext"
        :available-filters="$store.getters['outgoingInvoice2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"

        :is-button-add-disabled="!selectedItems.length"
        list-view-selectable="multi"
        :is-view-windows-ready="displayedCustomerInvoice!=null"

        @addItem="addPayment(selectedItems)"
        @selectedItems="selectedItems = $event"
        @showDetails="showDetails"
    >
      <template #listView_cell__reconciliationStatus="{item}">
        <b-badge
            v-if="item._reconciliationStatus != null"
            pill
            :variant="getBadgeColorReconciliationStatus(item)"
        >
          {{ $t(item._reconciliationStatus) }}
          <!--          <span v-if="item._reconciliationStatus == 'Unlettered'">-->
          <!--            {{-->
          <!--              $tc('Unlettered:',-->
          <!--                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),-->
          <!--                  {-->
          <!--                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),-->
          <!--                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')-->
          <!--                  }-->
          <!--              )-->
          <!--            }}-->
          <!--          </span>-->
          <!--          <span v-else>-->
          <!--            {{ $t(item._reconciliationStatus) }}-->
          <!--          </span>-->
        </b-badge>
      </template>

      <template #listView_cell__bankReconciliationStatus="{item}">
        <b-badge
            v-if="item._bankReconciliationStatus != null"
            pill
            :variant="getBadgeColorBankReconciliationStatus(item)"
        >
          {{ getBankReconciliationStatus(item) }}
          <!--          <span v-if="-->
          <!--          item._bankReconciliationStatus == 'Unpaid' &&-->
          <!--          moment() > moment(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))-->
          <!--">-->
          <!--            {{-->
          <!--              $tc('Unpaid:',-->
          <!--                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),-->
          <!--                  {-->
          <!--                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),-->
          <!--                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')-->
          <!--                  }-->
          <!--              )-->
          <!--            }}-->
          <!--          </span>-->
          <!--          <span v-else>-->
          <!--            {{ $t(item._bankReconciliationStatus) }}-->
          <!--          </span>-->
        </b-badge>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Download-->
        <button-download
            v-b-tooltip.hover.left="$t('Download')"
            @click.native.stop="downloadIncomingInvoice(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Payment-->
        <button-payment
            @click.native.stop="addPayment([item])"
            v-b-tooltip.hover.left="capitalize($t('addPayment'))"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

      </template>

      <template #leftViewWindow="{item}">
        <outgoing-invoice-card
            :outgoing-invoice="item"
            :is-viewable="true"
            :is-selected="item.id==displayedCustomerInvoice.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
            @pay="addPayment([item])"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedCustomerInvoice.billNumber }} -
        {{
          displayedCustomerInvoice.customerCompany ? displayedCustomerInvoice.customerCompany._display : displayedCustomerInvoice.customerIndividual._display
        }}
      </template>
      <template #viewWindowButtons>
        <!--          Download-->
        <button-download
            v-b-tooltip.hover.left="$t('Download')"
            @click.native.stop="downloadCustomerInvoice(displayedCustomerInvoice)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Payment-->
        <button-payment
            @click.native.stop="addPayment([displayedCustomerInvoice])"
            v-b-tooltip.hover.left="capitalize($t('addPayment'))"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <b-alert
            show
            :variant="customerInvoicePaymentBannerColor(displayedCustomerInvoice)"
        >
          <div class="alert-body d-flex justify-content-between">
            <icon
                icon="cash-register"
                class="top-0 my-auto"
            />
            <span class="ml-50 w-100 my-auto">
              <span v-if="customerInvoicePaymentBannerColor(displayedCustomerInvoice) == 'danger'">
                {{ $t('The payment of this supplier invoice is late') }}
              </span>
              <span v-else-if="customerInvoicePaymentBannerColor(displayedCustomerInvoice) == 'warning'">
                {{ $t('A partial payment has been recorded for this supplier invoice') }}
              </span>
              <span v-else>
                {{ $t('This supplier invoice is in Open status') }}
              </span>
            </span>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                class="text-nowrap"
                :variant="'outline-'+customerInvoicePaymentBannerColor(displayedCustomerInvoice)"
                @click="addPayment([displayedCustomerInvoice])"
            >
              {{ $t('addPayment') }}
            </b-button>
          </div>
        </b-alert>

        <customer-invoice-view :customer-invoice="displayedCustomerInvoice"/>
      </template>

    </base-template>


    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"

        @submitValidated="submitValidatedPaymentLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import BaseTemplate             from '../../../components/base3/Base.vue'
import ModalPayment             from '../../../components/prompt/Payment2.vue'

import useAPI from '../../../utils/useAPI'
import moment from 'moment'

import Ripple              from 'vue-ripple-directive'
import { usePayments }     from '../usePayment'
import store               from '../../../store'
import { capitalize }      from '../../../utils/filter'
import SupplierInvoiceView from '../../../components/views/SupplierInvoiceView.vue'
import ButtonDownload      from '../../../components/button/Download.vue'
import ButtonPayment       from '../../../components/button/Payment.vue'
import SupplierInvoiceCard from '../../../components/card/IncomingInvoice2.vue'
import OutgoingInvoiceCard from '../../../components/card/OutgoingInvoice2.vue'
import CustomerInvoiceView from '../../../components/views/CustomerInvoiceView.vue'
import i18n                from '../../../libs/i18n'

export default {
  directives: {
    Ripple,
  },
  components: {
    CustomerInvoiceView,
    OutgoingInvoiceCard,
    SupplierInvoiceCard,
    ButtonPayment,
    ButtonDownload,
    SupplierInvoiceView,
    ModalPayment,
    BaseTemplate
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('customerReceivables')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedCustomerInvoice = ref(null)
    const selectedItems = ref([])
    const currentPayment = ref({})
    const paymentModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { outgoingInvoices2, outgoingInvoicesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchOutgoingInvoices2(val)
          .then(() => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    watch(paymentModalShow, val => {
      if (val == false) {
        resetPayment()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOutgoingInvoices2,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
    } = useAPI()

    const { submitValidatedPayment } = usePayments()

    const loadInit = () => {
      fetchAutocompletePaymentMethods()
      fetchAutocompleteBankAccounts()
      resetPayment()
      isAutocompleteLoaded.value = true
    }

    const resetPayment = () => {
      selectedItems.value = []
      baseTemplate.value.listViewRef.resetSelected()
      currentPayment.value = {}
    }

    const showDetails = (customerInvoice) => {
      if (customerInvoice != null) {
        store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoice.id)
            .then(response => {
              displayedCustomerInvoice.value = response
            })

      } else {
        displayedCustomerInvoice.value = null
      }
    }

    const addPayment = (customerInvoices) => {
      if (customerInvoices.length) {
        let amount = 0
        let paymentRelations = []
        customerInvoices.forEach(ii => {
          amount += parseFloat(ii._balance)
          paymentRelations.push({
            amount: parseFloat(ii._balance),
            outgoingInvoice: ii
          })
        })

        store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoices[0].id)
            .then(response => {
              currentPayment.value = {
                paymentMethod: response.paymentMethod,
                date: (new Date()).toISOString().slice(0, 10),
                paymentRelations: paymentRelations,
                bankAccount: response.bankAccount
              }

              paymentModalShow.value = true
            })
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            paymentModalShow.value = false
          })
    }

    const downloadCustomerInvoice = (outgoingInvoice) => {
      store.dispatch('outgoingInvoice2/printOutgoingInvoice', outgoingInvoice)
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          })
    }

    const getBadgeColorReconciliationStatus = (item) => {
      if (item._reconciliationStatus == 'Lettered') {
        return 'success'
      } else if (item._reconciliationStatus == 'Partially lettered') {
        return 'warning'
      } else if (item._reconciliationStatus == 'Unlettered') {
        return 'danger'
      }
    }

    const getBadgeColorBankReconciliationStatus = (item) => {
      if (item._reconciliationStatus == "Open") {
        return 'secondary'
      } else {
        if (item._bankReconciliationStatus == 'Paid') {
          return 'success'
        } else if (item._bankReconciliationStatus == 'Partially paid') {
          return 'warning'
        } else if (item._bankReconciliationStatus == 'Unpaid') {
          return 'danger'
        }
      }

    }

    const getDaysRemaining = (dueDate) => {
      return parseInt(moment.duration(moment(dueDate).diff(moment().startOf('day'))).asDays())
    }

    const customerInvoicePaymentBannerColor = (customerInvoice) => {
      if (customerInvoice._paymentStatus == 'Waiting for payment') {
        return 'secondary'
      } else if (customerInvoice._paymentStatus == 'Partial payment') {
        if (moment() > moment(customerInvoice.billingDate)) {
          return 'danger'
        } else {
          return 'warning'
        }
      } else {
        return 'danger'
      }
    }

    const getBankReconciliationStatus = (item) => {
      if (item._bankReconciliationStatus == 'Unpaid') {
        if (moment() > moment(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))) {
          return i18n.tc('Unpaid:',
              getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),
              {
                days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),
                suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? i18n.t('remaining') : i18n.t('ofLate')
              }
          )
        } else {
          return i18n.t('Open')
        }
      } else {
        return i18n.t(item._bankReconciliationStatus)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
            listGlobalAmount: currentView.listGlobalAmount,
          }

        })

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,

      displayedCustomerInvoice,
      selectedItems,
      currentPayment,
      paymentModalShow,

      // Computed
      outgoingInvoices2,
      outgoingInvoicesContext,

      // Methods
      submitValidatedPayment,
      showDetails,
      addPayment,
      submitValidatedPaymentLocal,
      downloadCustomerInvoice,
      getBadgeColorReconciliationStatus,
      getBadgeColorBankReconciliationStatus,
      getDaysRemaining,
      customerInvoicePaymentBannerColor,
      getBankReconciliationStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>